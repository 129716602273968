import styled from 'styled-components';
import { atMinWidth } from '../../atoms/breakpoints';
import { colors } from '../../atoms/colors';
import { font } from '../../atoms/typography';

export const FilteredBlogPostsStyles = styled.div`
  padding-top: 20px;
  background-color: ${colors.base.heroSurface};

  .breadcrumbs-row {
    padding-left: 15px;
    margin-top: 10px;

    .breadcrumb {
      ${font('text', 'sm', '400')}
      color: var(--text-color);
    }
  }

  ${atMinWidth.md`
    padding-top: 60px;
  `}

  .list-header {
    margin-bottom: 32px;

    ${atMinWidth.md`
      margin-bottom: 60px;
    `}
  }

  .title {
    ${font('display', 'lg', '700')};
    margin-top: 32px;

    ${atMinWidth.md`
      ${font('display', 'xl', '700')}
    `}
  }

  .list-footer {
    margin-bottom: 80px;
    position: relative;
    padding: 0 15px;
  }

  .blog-card,
  .pagination-container {
    margin-bottom: 32px;
  }

  .subscribe-form {
    > * {
      max-width: 100%;
    }

    .form-wrapper {
      .form-header {
        max-width: 480px;
      }
    }
  }
`;
